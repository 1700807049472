import { ComponentType, lazy, LazyExoticComponent } from "react";

function lazyWithLogout<T extends ComponentType<any>>(
  load: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(async () => {
    try {
      return await load();
    } catch (error) {
      console.error("Component Failed Loading:", error);
      localStorage.clear(); // Logout user when component can not be lazy-loaded
      window.location.pathname = "/"; // Redirect to main page to prevent issues with app state
      throw error;
    }
  });
}

export default lazyWithLogout;
