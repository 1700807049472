import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { useIntl } from "react-intl";

// ==============================|| SNACKBAR HOOKS ||============================== //
const useSnackbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showSnackbar = (message: string, color: "success" | "error" | "warning") => {
    dispatch(
      openSnackbar({
        open: true,
        message: intl.formatMessage({ id: message || "successFormAlert" }),
        variant: "alert",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        transition: "SlideLeft",
        alert: {
          color: color,
        },
        close: false,
      })
    );
  };
  return showSnackbar;
};
export default useSnackbar;
