import { useEffect, useState, useCallback } from "react";
import { Router as RemixRouter } from "@remix-run/router";
import { RouterProvider } from "react-router-dom";

// routing
import createRouter from "routes";

// version checking
import useCheckVersion from "hooks/useCheckVersion";

//utils
import { getInitialPage } from "routes/utils";

const Router = () => {
  // Placed here as a root for any other page with Snackbars still available to be rendered
  useCheckVersion();

  const [router, setRouter] = useState<RemixRouter>(createRouter());
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const redirectToInitial = useCallback(() => {
    if (shouldRedirect) {
      router.navigate(getInitialPage());
      setShouldRedirect(false);
    }
  }, [router, shouldRedirect]);

  useEffect(() => {
    const storageListener = () => {
      setRouter(createRouter());
      setShouldRedirect(true);
      window.dispatchEvent(new Event("router-set"));
    };

    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    redirectToInitial();
  }, [redirectToInitial]);

  return <RouterProvider router={router} />;
};

export default Router;
